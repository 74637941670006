import React, { memo, ReactNode, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import i18n from './i18n/i18n';
import { Spin } from 'antd';

const GeneralPageLazy = React.lazy(() => import('./pages/[locales]'));
const LoginPageLazy = React.lazy(() => import('./pages/[locales]/login'));
const WhatWePageLazy = React.lazy(() => import('./pages/[locales]/what-we-check'));
const PersonalAccountPageLazy = React.lazy(() => import('./pages/[locales]/personal-account'));
const SuccessPageLazy = React.lazy(() => import('./pages/[locales]/success'));
const FailPageLazy = React.lazy(() => import('./pages/[locales]/fail'));
const VerificationPageLazy = React.lazy(() => import('./pages/[locales]/verification'));
const RestorePasswordPageLazy = React.lazy(() => import('./pages/[locales]/restore-password'));

interface SuspenseWrapperProps {
  children: ReactNode;
}

const SuspenseWrapper: React.FC<SuspenseWrapperProps> = ({ children }) => {
  const lang = i18n.language;

  if (!lang) {
    return (
      <div className="load">
        <Spin />
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="load">
          <Spin />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <SuspenseWrapper>
              <GeneralPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/:locale"
          element={
            <SuspenseWrapper>
              <GeneralPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/:locale/login"
          element={
            <SuspenseWrapper>
              <LoginPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/login"
          element={
            <SuspenseWrapper>
              <LoginPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/:locale/what-we-check"
          element={
            <SuspenseWrapper>
              <WhatWePageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/what-we-check"
          element={
            <SuspenseWrapper>
              <WhatWePageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/:locale/personal-account"
          element={
            <SuspenseWrapper>
              <PersonalAccountPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/personal-account"
          element={
            <SuspenseWrapper>
              <PersonalAccountPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/:locale/success"
          element={
            <SuspenseWrapper>
              <SuccessPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/success"
          element={
            <SuspenseWrapper>
              <SuccessPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/:locale/fail"
          element={
            <SuspenseWrapper>
              <FailPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/fail"
          element={
            <SuspenseWrapper>
              <FailPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/:locale/finalize"
          element={
            <SuspenseWrapper>
              <VerificationPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/finalize"
          element={
            <SuspenseWrapper>
              <VerificationPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/:locale/restore/password"
          element={
            <SuspenseWrapper>
              <RestorePasswordPageLazy />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/restore/password"
          element={
            <SuspenseWrapper>
              <RestorePasswordPageLazy />
            </SuspenseWrapper>
          }
        />
      </Routes>
    </Router>
  );
}

export default memo(App);
